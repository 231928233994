<template>
    <main class="absolute inset-0">
      <slot />
    </main>
  </template>
  
  <script lang="ts">
  import store from "../stores/index";
  import { defineComponent, onMounted } from "vue";
  
  export default defineComponent({
    name: "AppLayoutApp",
    setup() {
      onMounted(() => store.init());
    },
  });
  </script>
  